
import { mixins, Component } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import WidgetMixin from '~/mixins/widget.mixin';

@Component({
  name: 'dn-banner-list-auto',
})
export default class BannerListAuto extends mixins(DeviceMixin, WidgetMixin) {
  private cols: number = 1;
}
